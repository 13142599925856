import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/design-system/design-system/node_modules/gatsby-theme-docz/src/base/Layout.js";
import PageHeader from '~/components/PageHeader';
import { HvaViGjor } from '~/components/HvaViGjor';
import { GridItem, GridContainer } from '@entur/grid';
import Regjeringen from './Regjeringen.png';
import './hva-vi-gjor.scss';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader" />
    <HvaViGjor mdxType="HvaViGjor"></HvaViGjor>
    <h2 {...{
      "id": "sektorpolitiske-mål"
    }}>{`Sektorpolitiske mål`}</h2>
    <GridContainer spacing="medium" mdxType="GridContainer">
  <GridItem className="hva-vi-gjor__goal hva-vi-gjor__goal-image" small={6} medium={4} large={4} mdxType="GridItem">
    <img alt="Regjeringen sin logo" src={Regjeringen} height="56px" />
  </GridItem>
  <GridItem className="hva-vi-gjor__goal hva-vi-gjor__goal-text" small={6} medium={4} large={4} mdxType="GridItem">
    Enklere reisehverdag og økt konkurranseevne for næringslivet
  </GridItem>
  <GridItem className="hva-vi-gjor__goal hva-vi-gjor__goal-text" small={6} medium={4} large={4} mdxType="GridItem">
    Mer for pengene
  </GridItem>
  <GridItem className="hva-vi-gjor__goal hva-vi-gjor__goal-text" small={6} medium={4} large={4} mdxType="GridItem">
    Effektiv bruk av ny teknologi
  </GridItem>
  <GridItem className="hva-vi-gjor__goal hva-vi-gjor__goal-text" small={6} medium={4} large={4} mdxType="GridItem">
    Nullvisjon for drepte og hardt skadde
  </GridItem>
  <GridItem className="hva-vi-gjor__goal hva-vi-gjor__goal-text" small={6} medium={4} large={4} mdxType="GridItem">
    Bidra til oppfyllelse av Norges klima- og miljømål
  </GridItem>
    </GridContainer>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      